import React from 'react';



class ProjectControl extends React.Component {

    constructor(props) {
        super(props);

        if ((this.props.projectControl !== undefined) && (this.props.projectControl != null)) {

            this.state = {
                salesIsOn: this.props.projectControl.salesison,
                preSaleIsOn: this.props.projectControl.presaleison
    
            };

        }else{

            this.state = {
                salesIsOn: false,
                preSaleIsOn: false,
            };


        }

        this.handleOnSale = this.handleOnSale.bind(this)
        this.handleOnPreSale = this.handleOnPreSale.bind(this)


    }

    handleOnSale(){

        this.setState({salesIsOn: !this.state.salesIsOn})

    }

    handleOnPreSale(){
        this.setState({salesIsOn: !this.state.salesIsOn})
    }
    
    render() {
        return (
            <section className="what-we-do-area bg-fafafb pt-0 pb-5">

                <div className="container ">
                    <div className="row">
                        { ((this.props.projectControl === undefined )|| (this.props.projectControl == null)) ?

                            <div className="col-sm-12">

                                <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">

                                        <form action="/projects" method="post" onSubmit={(e) => {
                                            //e.preventDefault() 
                                            //       required
                                            //      disabled
                                            //      readOnly
                                            //console.log(this.ethaddress.value)
                                            //console.log(this.amount.value)
                                            //console.log(this.authenticity_token.value)
                                        }}>

                                            <input
                                                type='hidden'
                                                name="authenticity_token"
                                                value={this.props.authenticity_token}
                                                ref={(input) => { this.authenticity_token = input }}
                                            />
                                            <input
                                                type='hidden'
                                                name="project[redirect]"
                                                value={true}
                                            />

                                            <div className="form-check form-check-inline mb-3 mx-5">
                                                <input
                                                    type='hidden'
                                                    name="project[salesison]"
                                                    value={0}
                                                />
                                                <input
                                                    id="project_salesison"
                                                    type='checkbox'
                                                    className="form-check-input mb-1"
                                                    name="project[salesison]"
                                                    value={1}
                                                    ref={(input) => { this.salesison = input }}
                                                />
                                                <label className="form-check-label" htmlFor="project_salesison">Sale is On</label>
                                            </div>
                                            <div className="form-check form-check-inline mb-3  mx-5">

                                                <input
                                                    type='hidden'
                                                    name="project[presaleison]"
                                                    value={0}
                                                />
                                                <input
                                                    id="Presale-is-on"
                                                    type='checkbox'
                                                    className="form-check-input mb-1"
                                                    name="project[presaleison]"
                                                    value={1}
                                                    ref={(input) => { this.presalesison = input }}
                                                />
                                                <label className="form-check-label" htmlFor="Presale-is-on">Presale is On</label>
                                            </div>
                                            <div className="form-group ">
                                                <input type='submit' className='btn btn-block btn-primary' value='Set Project' />
                                            </div>

                                        </form>

                                        <hr />
                                    </div>

                                </div>

                            </div>


                            :

                            <div className="col-sm-12">

                                <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">
                                    <form action={"/projects/"+this.props.projectControl.id} method="post" onSubmit={(e) => {
                                            //e.preventDefault() 
                                            //       required
                                            //      disabled
                                            //      readOnly
                                            //console.log(this.ethaddress.value)
                                            //console.log(this.amount.value)
                                            //console.log(this.authenticity_token.value)
                                        }}>
                                            <input
                                                type='hidden'
                                                name="_method"
                                                value="patch"
                                                ref={(input) => { this._method = input }}

                                            />
                                            <input
                                                type='hidden'
                                                name="authenticity_token"
                                                value={this.props.authenticity_token}
                                                ref={(input) => { this.authenticity_token = input }}
                                            />
                                            <input
                                                type='hidden'
                                                name="project[redirect]"
                                                value={true}
                                            />

                                            <div className="form-check form-check-inline mb-3 mx-5">
                                                <input
                                                    type='hidden'
                                                    name="project[salesison]"
                                                    value={0}
                                                />
                                                <input
                                                    id="project_salesison"
                                                    type='checkbox'
                                                    className="form-check-input mb-1"
                                                    name="project[salesison]"
                                                    defaultChecked={this.state.salesIsOn}
                                                    onChange={this.handleOnSale}
                                                    value={1}
   
                                                />
                                                <label className="form-check-label" htmlFor="project_salesison">Sale is On</label>
                                            </div>
                                            <div className="form-check form-check-inline mb-3  mx-5">

                                                <input
                                                    type='hidden'
                                                    name="project[presaleison]"
                                                    value={0}
                                                />
                                                <input
                                                    id="Presale-is-on"
                                                    type='checkbox'
                                                    className="form-check-input mb-1"
                                                    name="project[presaleison]"
                                                    defaultChecked={this.state.preSaleIsOn}
                                                    onChange={this.handleOnPreSale}
                                                    value={1}
                                                />
                                                <label className="form-check-label" htmlFor="Presale-is-on">Presale is On</label>
                                            </div>
                                            <div className="form-group ">
                                                <input type='submit' className='btn btn-block btn-primary' value='Set Project' />
                                            </div>

                                        </form>

                                        <hr />
                                    </div>

                                </div>

                            </div>



                        }
                    </div>
                </div>


            </section>
        )
    }
}

export default ProjectControl;