import React from 'react';

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText,tag}) => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul>
                        <li>
                            <a href={homePageUrl} >{homePageText}</a>
                        </li>
                        { tag? 
                        <li className="tag-style" >{activePageText}</li>
                        :
                        <li>{activePageText}</li>
                        }

                    </ul>
                </div>
            </div>

            <div className="shape-img1">
                <img src={require("shape/shape1.svg")} alt="image" />
            </div>
            <div className="shape-img2">
                <img src={require("shape/shape2.png")} alt="image" />
            </div>
            <div className="shape-img3">
                <img src={require("shape/shape3.png")} alt="image" />
            </div>
        </div>
    );
}

export default PageBanner;